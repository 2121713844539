import { useContext } from 'react';
import { Translator } from '@context';

const PaginationText = ({ pagination }) => {
  const translator = useContext(Translator.Context);
  return (
    <p className="text-sm text-gray-500 dark:text-gray-400 flex items-center">
      {translator('Affiche ')}
      <span className="text-gray-900 dark:text-gray-100 mx-1 font-medium">
        {(pagination?.page - 1) * pagination?.pageSize + 1}
      </span>{' '}
      {translator('à')}
      <span className="text-gray-900 dark:text-gray-100 mx-1 font-medium">
        {' '}
        {Math.min(pagination?.page * pagination?.pageSize, pagination?.total)}{' '}
      </span>{' '}
      {translator('de ')}
      <span className="text-gray-900 dark:text-gray-100 mx-1 font-medium">{pagination?.total}</span>
      {translator('résultats')}
    </p>
  );
};

export default PaginationText;
