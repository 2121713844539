import logo from '@assets/logo.png';
import { useContext } from 'react';
import { Translator } from '@context';

export default function NotFound() {
  const translator = useContext(Translator.Context);
  return (
    <>
      <div className="flex min-h-full flex-col bg-white dark:bg-slate-900 pb-12 pt-16">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <a href="/" className="inline-flex">
              <img className="h-12 w-auto" src={logo} alt="" />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-base font-semibold text-teal-600">404</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-white">
                {translator('Page inconnue.')}
              </h1>
              <p className="mt-2 text-base text-gray-500 dark:text-gray-400">
                {translator("Désolé, nous n'avons pas pu trouver la page que vous souhaitiez.")}
              </p>
              <div className="mt-6">
                <a href="/" className="text-base font-medium text-teal-600 hover:text-teal-500">
                  {translator("Retourner à l'écran d'accueil")}
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
