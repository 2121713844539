import moment from 'moment';

const strapiTypeFormattor = (value, type) => {
  console.log(value, type);

  return {
    // String types
    string: value,
    text: value,
    richtext: value,
    enumeration: value,
    email: value,
    password: value,
    uid: value,
    // Number types
    number: value,
    integer: value,
    biginteger: value,
    float: value,
    decimal: value,
    // Boolean
    boolean: value,
    // Dates
    date: value,
    time: moment(value, 'HH:mm:ss').format('HH:mm:ss.SSS'),
    datetime: value,
    timestamp: value,
    // Relations
    relation: value
  }[type];
};

export const strapiFiltersTranslator = (filters, config = []) => ({
  $and: filters?.map(
    (f) =>
      config
        ?.find((c) => c?.key === f?.field?.key) // Match the root element in config with the root element in strapi
        ?.key?.split('.') // Extract the full key (with its children)
        ?.toReversed() // Reverse the array the recursively create obj of obj
        ?.reduce((prev, curr) => ({ [curr]: prev }), {
          [f?.option?.val]: strapiTypeFormattor(f?.value, f?.field?.type)
        }) // Recursively create obj of obj
  )
});
