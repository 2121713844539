import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { RadioGroup, Alert, InputText } from '@components';
import Button from '@thoody-consulting/button';
import { motion } from 'framer-motion';
import { Layout } from '@custom';
import { Form } from '@layouts';
import { Configuration, Translator } from '@context';
import axios from 'axios';
import baseUrl from '@root/config';
import { configToken } from '@queries/utils';

const Profile = (props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const configuration = useContext(Configuration.Context);
  const translator = useContext(Translator.Context);
  const mutation = useMutation((data) =>
    axios.put(`${baseUrl}/api/configuration`, { data: data[0] }, configToken)
  );

  const { links = [], pageName = '' } = props;

  return (
    <Layout links={links} pageName={pageName}>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ x: window.innerWidth }}
        className="w-full">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl pt-10">
            <Form
              mutation={mutation}
              callback={() => {
                queryClient.invalidateQueries('configuration');
                navigate(-1);
              }}>
              <div className="space-y-12">
                <div className="border-b border-gray-900/10 dark:border-white/10 pb-12">
                  <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                    {translator('Configuration')}
                  </h2>
                  <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                    {translator(
                      'Ce formulaire permet personnaliser les différents champs de configurations'
                    )}
                  </p>

                  <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    {mutation?.isError && (
                      <Alert color="red" className="mb-4 col-span-full">
                        {translator(
                          "Une erreur s'est produite lors de la modification des informations"
                        )}
                        <br />
                        {translator('Veuillez réessayer.')}
                      </Alert>
                    )}
                    <input type="hidden" name="id" value={configuration?.id}></input>
                    <InputText
                      label={translator('Forfait')}
                      className="col-span-full"
                      value={configuration?.subscription}
                      disabled
                      readOnly
                    />

                    <RadioGroup
                      className="col-span-full"
                      name="registration"
                      label={translator('Inscription de nouveau utilisateurs')}
                      defaultValue={configuration?.registration}
                      data={[
                        { label: `${translator('Interdite')}`, value: 'blocked' },
                        { label: `${translator('Avec validation')}`, value: 'controlled' },
                        { label: `${translator('Libre')}`, value: 'direct' }
                      ]}
                      isHorizontal
                    />
                    <InputText
                      label={translator(
                        'Durée de gel des modifications des réservations (en heures) *'
                      )}
                      name="bookingFreeze"
                      type="number"
                      className="col-span-full"
                      defaultValue={configuration?.bookingFreeze}
                      required
                    />
                    <InputText
                      label={translator('Durée fixe des réservations (en minutes) *')}
                      name="fixedDuration"
                      type="number"
                      className="col-span-full"
                      defaultValue={configuration?.fixedDuration}
                      required
                    />
                    <RadioGroup
                      className="col-span-full"
                      name="enableGroups"
                      label={translator('Activer la gestion par Groupe')}
                      defaultValue={configuration?.enableGroups?.toString()}
                      data={[
                        { label: `${translator('Désactivé')}`, value: 'false' },
                        { label: `${translator('Activé')}`, value: 'true' }
                      ]}
                      isHorizontal
                    />
                    <RadioGroup
                      className="col-span-full"
                      name="enableCredits"
                      label={translator('Activer la gestion par Credits')}
                      defaultValue={configuration?.enableCredits?.toString()}
                      data={[
                        { label: `${translator('Désactivé')}`, value: 'false' },
                        { label: `${translator('Activé')}`, value: 'true' }
                      ]}
                      isHorizontal
                    />
                  </div>
                </div>
              </div>
              <div className={`mt-6 flex items-center gap-x-6 justify-end`}>
                <div className="flex gap-x-6">
                  <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200"
                    onClick={() => navigate(-1)}>
                    {translator('Annuler')}
                  </button>
                  <Button
                    type="submit"
                    className="dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300"
                    isWrong={mutation?.isError}
                    isLoading={mutation?.isLoading}
                    onClick={() => {}}>
                    {translator('Enregistrer')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </motion.div>
    </Layout>
  );
};

export default Profile;
