import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import logo from '@assets/logo.png';
import { Form } from '@layouts';
import queries from '@queries';
import baseUrl from '@root/config';
import { configToken } from '@queries/utils';
import { useContext } from 'react';
import { Translator } from '@context';

import { InputText, Alert, Loading } from '@components';
import Button from '@thoody-consulting/button';

function Auth(props) {
  const translator = useContext(Translator.Context);
  const queryClient = useQueryClient();
  const me = useQuery(['me'], () => queries.getAll('/api/users/me', { populate: '*' }), {
    retry: false
  });
  const loginMutation = useMutation((data) =>
    axios.post(`${baseUrl}/api/auth/local`, data?.[0], configToken)
  );

  return me?.isLoading ? (
    <Loading
      src={
        'https://raw.githubusercontent.com/n3r4zzurr0/svg-spinners/main/preview/90-ring-with-bg-black-36.svg'
      }
      className={'animate-spin h-8 dark:invert'}
    />
  ) : me?.isSuccess && me?.data?.role?.name == 'Admin' ? (
    props.children
  ) : (
    <div className="flex flex-col justify-center items-center h-screen bg-gray-50/50 dark:bg-gray-900">
      <div className="overflow-hidden shadow rounded-lg divide-y bg-white border dark:bg-gray-800/50 dark:border-gray-700 divide-gray-200  dark:divide-gray-800">
        <div className="px-4 py-5 sm:px-6 flex flex-col items-center justify-center min-w-[20rem]">
          <img className="max-w-[8rem]" src={logo} />
          <p className="mt-4 text-base font-montserrat dark:text-white">
            {translator('Thoody Booking - Admin')}
          </p>
        </div>
        <Form
          mutation={loginMutation}
          callback={(r) => {
            sessionStorage.setItem('token', r?.data?.jwt);
            queryClient.invalidateQueries('me');
            window.location.reload();
          }}>
          <div className="px-4 py-6 sm:p-6 flex flex-col gap-4">
            {me?.isSuccess && me?.data?.role?.name != 'Admin' && (
              <Alert color="yellow" className="mb-4 max-w-[18rem]">
                {translator("Votre compte n'est pas administrateur.")}
              </Alert>
            )}
            {loginMutation?.isError && (
              <Alert color="red" className="mb-4">
                {translator("Le nom d'utilisateur et le mot ")}
                <br />
                {translator('de passe ne semblent pas correspondre.')}
              </Alert>
            )}
            <InputText name="identifier" label={translator('Utilisateur')} />
            <InputText type="password" name="password" label={translator('Mot de passe')} />
            <div className="flex flex-col sm:flex-row-reverse ">
              <Button
                type="submit"
                isWrong={loginMutation?.isError}
                isLoading={loginMutation?.isLoading}
                color=""
                textColor="white"
                onClick={() => {}}
                className={
                  'inline-flex mt-3 gap-0.5 justify-center overflow-hidden text-sm font-medium transition rounded-fullpy-1 px-3 bg-zinc-900 hover:bg-zinc-700 dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300'
                }>
                {translator('Se connecter')}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Auth;
