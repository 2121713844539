import { useNavigate, useParams } from 'react-router-dom';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Form } from '@layouts';
import { Alert } from '@components';
import { motion } from 'framer-motion';
import queries, { displayError } from '@queries';
import { configToken } from '@queries/utils';
import { useContext } from 'react';
import { Translator } from '@context';
import Button from '@thoody-consulting/button';

const EditForm = (props) => {
  const translator = useContext(Translator.Context);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pageName, id } = useParams();

  const mutationCreate = useMutation((data) =>
    queries?.post(`/api/${pageName}s`, data, configToken, !!props.raw)
  );
  const mutationUpdate = useMutation((data) =>
    queries?.put(`/api/${pageName}s`, data, configToken, !!props.raw)
  );
  const mutationDelete = useMutation((data) => queries?.delete(`/api/${pageName}s`, data));
  const mutation = id ? mutationUpdate : mutationCreate;

  if (mutationDelete?.isSuccess || mutation?.isSuccess) {
    queryClient.invalidateQueries(pageName);
    navigate(`/${pageName}`);
  }

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ x: window.innerWidth }}
      className="w-full">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl pt-10">
          <Form mutation={mutation}>
            <div className="space-y-12">
              <div className="border-b border-gray-900/10 dark:border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                  {props?.title}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
                  {props?.description}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                  {(mutation?.isError || mutationDelete?.isError) && (
                    <Alert color="red" className="mb-4 col-span-full">
                      {translator('Une erreur est survenue : ')}
                      {displayError(mutation?.error?.response)}
                    </Alert>
                  )}
                  {props.children}
                </div>
              </div>
            </div>
            <div className={`mt-6 flex items-center gap-x-6 justify-${id ? 'between' : 'end'}`}>
              {id && (
                <Button
                  color="red"
                  type="button"
                  className="dark:bg-red-400/10 dark:text-red-400 dark:ring-1 dark:ring-inset dark:ring-red-400/20 dark:hover:bg-red-400/10 dark:hover:text-red-300 dark:hover:ring-red-300"
                  isWrong={mutationDelete?.isError}
                  isLoading={mutationDelete?.isLoading}
                  onClick={() => mutationDelete?.mutate([{ id }])}>
                  {translator('Supprimer')}
                </Button>
              )}
              <div className="flex gap-x-6">
                <button
                  type="button"
                  className="text-sm font-semibold leading-6 text-gray-900 dark:text-gray-200"
                  onClick={() => navigate(-1)}>
                  {translator('Annuler')}
                </button>
                <Button
                  type="submit"
                  className="dark:bg-emerald-400/10 dark:text-emerald-400 dark:ring-1 dark:ring-inset dark:ring-emerald-400/20 dark:hover:bg-emerald-400/10 dark:hover:text-emerald-300 dark:hover:ring-emerald-300"
                  isWrong={mutation?.isError}
                  isLoading={mutation?.isLoading}
                  onClick={() => {}}>
                  {id ? translator('Modifier') : translator('Enregistrer')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </motion.div>
  );
};

export default EditForm;
