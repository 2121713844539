import { useState, useContext } from 'react';
import { Section, PaginationText, Layout } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table, FilterButton, Pagination, Notification } from '@components';
import { strapiFiltersTranslator } from '@queries/translators';
import moment from 'moment';
import { Translator, Configuration } from '@context';
import { CalendarDaysIcon } from '@heroicons/react/24/solid';
import baseUrl from '@root/config';

const config = (translator, copyToClipboard, displayCalendar) => [
  {
    key: 'id',
    label: translator('Id'),
    type: 'integer',
    display: (o) => o?.id,
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'name',
    label: translator('Salle'),
    type: 'text',
    display: (o) => (
      <>
        <div className="hidden md:table-cell">{o?.name || ''}</div>
        <div className="table-cell md:hidden">
          <div className="text-gray-900">
            {o?.name} - {o?.capacity}
          </div>
          <div className="mt-1 text-gray-500">
            {o?.openingHour ? moment(o?.openingHour, 'HH:mm').format('HH:mm') : ''} -
            {o?.closingHour ? moment(o?.closingHour, 'HH:mm').format('HH:mm') : ''}
          </div>
        </div>
      </>
    )
  },
  {
    key: 'capacity',
    label: translator('Capacité'),
    type: 'integer',
    display: (o) => o?.capacity || '',
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'openingHour',
    label: translator("Heure d'ouverture"),
    type: 'time',
    display: (o) => (o?.openingHour ? moment(o?.openingHour, 'HH:mm').format('HH:mm') : ''),
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'closingHour',
    label: translator('Heure de fermeture'),
    type: 'time',
    display: (o) => (o?.closingHour ? moment(o?.closingHour, 'HH:mm').format('HH:mm') : ''),
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'connectivity',
    label: translator('Abonnement iCal'),
    display: (o) => (
      <button
        type="button"
        className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10 isolate shadow-sm dark:text-white dark:bg-white/5 dark:hover:bg-white/10 dark:ring-white/10"
        onClick={() => copyToClipboard(o)}>
        <CalendarDaysIcon className="-ml-0.5 h-5 w-5 dark:text-gray-400" aria-hidden="true" />
        Copier le lien
      </button>
    ),
    classNameColumn: !displayCalendar && 'hidden'
  }
];

function RoomPage(props) {
  const navigate = useNavigate();
  const translator = useContext(Translator.Context);
  const configuration = useContext(Configuration.Context);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showNotification, setShowNotification] = useState(false);

  const { links = [], pageName = '' } = props;

  const { data, isLoading: isLoading } = useQuery(['room', filters, sort, currentPage], () =>
    queries.getAllWithPagination(`/api/rooms`, {
      filters: strapiFiltersTranslator(
        filters,
        config(translator, copyToClipboard, displayCalendar)
      ),
      sort: sort,
      pagination: {
        page: currentPage,
        pageSize: 20
      }
    })
  );

  const copyToClipboard = (o) => {
    navigator.clipboard.writeText(
      `${baseUrl}/api/ical?token=${sessionStorage.getItem('token')}&roomId=${o?.id}`
    );
    setShowNotification(true);
  };

  const tableData = isLoading ? [] : data?.data;
  const pagination = isLoading ? {} : data?.meta?.pagination;
  const displayCalendar = configuration.subscription === 'connectivity';

  return (
    <Layout links={links} pageName={pageName}>
      <Section title="Salles" description="Liste des salles" onClick={() => navigate('/edit/room')}>
        <Notification show={showNotification} setShow={setShowNotification}>
          <p className="text-sm font-medium text-gray-900">Copié !</p>
          <p className="mt-1 text-sm text-gray-500">
            Le lien vers l&apos;abonnement iCal a été copié dans le presse-papier.
          </p>
        </Notification>
        <FilterButton
          className="mt-5"
          config={config(translator, copyToClipboard, displayCalendar)}
          filters={filters}
          setFilters={setFilters}
          label={translator('Filtres')}
          language="fr"
        />
        <Table
          data={tableData}
          config={config(translator, copyToClipboard, displayCalendar)}
          onClick={(_, o) => navigate(`/edit/room/${o?.id}`)}
          className={'mt-2 sm:mt-5'}
          isLoading={isLoading}
          sort={sort}
          setSort={setSort}
        />
        {!isLoading && (
          <div className="flex justify-between py-6">
            <PaginationText pagination={pagination} />
            <Pagination
              maxPage={pagination?.pageCount}
              range={5}
              currentPage={Math.min(currentPage, pagination?.pageCount) || 1}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </Section>
    </Layout>
  );
}

export default RoomPage;
