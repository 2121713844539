/* eslint-disable */
import { useState, useContext } from 'react';
import { Section, PaginationText, Badge, Layout } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table, FilterButton, Pagination } from '@components';
import _ from 'lodash';
import {Translator} from '@context';

const pageSize = 20;

const config = (translator) => [
  { key: 'id', label: translator('Id'), type: 'integer', display: (o) => o?.id },
  { key: 'username', label: translator('Username'), type: 'string', display: (o) => o?.username || '' },
  { key: 'email', label: translator('Email'), type: 'email', display: (o) => o?.email || '' },
  // {key: 'creationGroups', label: translator('Groupes de création'), display: (o) => o?.creationGroups.map((g) => (<li>{g.name}</li>))},
  // {key: 'participationGroups', label: translator('Groupes de participation'), display: (o) => o?.participationGroups.sort((a, b) => a.id - b.id).map((g) => (<li>{g.name}</li>))},
  { key: 'role.name', label: translator('Status'), type: 'relation', display: (o) => <Badge color={{
    'Authenticated': 'green',
    'Pending': 'yellow',
    'Admin': 'blue'
  }[o?.role?.name]}>{o?.role?.name}</Badge> || ''}
];

function UserPage(props) {
  const translator = useContext(Translator.Context)
  const navigate = useNavigate();

  const { links = [], pageName = ''} = props;

  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading: isLoading } = useQuery(
    ['user','full'],
    () => queries.getAll(`/api/users`, { populate: '*'})
  );

  const [sortKeys, sortDirections] = _.unzip(sort?.map(s => s?.split(':')));
  
  const dataSorted = isLoading ? [] : _.orderBy(data, sortKeys?.map((val) => ((o) => o?.[val]?.toLowerCase())), sortDirections);

  const dataFiltered = dataSorted?.filter(o => filters.every(f => _.get(o, f?.field?.key) === f?.value));
  const dataPages = _.chunk(dataFiltered, pageSize);

  return (
    <Layout links={links} pageName={pageName}>
        <Section
          title="Utilisateurs"
          description={translator("Liste des utilisateurs")}
          onClick={() => navigate('/edit/user')}>
          <FilterButton
            className="mt-5"
            config={config(translator)}
            filters={filters}
            setFilters={setFilters}
            label={translator('Filtres')}
            language="fr"
            customOptions={[{ val: '$eq', fr: 'égal', en: 'equal' }]}
          />
          <Table
            data={dataPages?.[currentPage - 1]}
            onClick={(_, o) => navigate(`/edit/user/${o?.id}`)}
            className={'mt-2 sm:mt-5'}
            isLoading={isLoading}
            config={config(translator)}
            sort={sort}
            setSort={setSort}
          />
          {!isLoading && (
            <div className="flex justify-between py-6">
              <PaginationText pagination={{
                page: currentPage,
                pageSize,
                total: data?.length || 0
              }} />
              <Pagination
                maxPage={dataPages.length}
                range={5}
                currentPage={Math.min(currentPage, dataPages.length) || 1}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </Section>
    </Layout>
  );
}

export default UserPage;
