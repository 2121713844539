import { useState, useContext } from 'react';
import { Section, PaginationText, Layout } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table, FilterButton, Pagination } from '@components';
import { strapiFiltersTranslator } from '@queries/translators';
import { Translator } from '@context';

const config = (translator) => [
  {
    key: 'id',
    label: `${translator('Id')}`,
    type: 'integer',
    display: (o) => o?.id,
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'groups',
    label: `${translator('Groupe')}`,
    type: 'text',
    display: (o) => o?.groups.map((o) => o.name).join(', ')
  },
  {
    key: 'owner',
    label: `${translator('User')}`,
    type: 'text',
    display: (o) => o?.owner?.username
  },
  {
    key: 'value',
    label: `${translator('Valeur')}`,
    type: 'number',
    display: (o) => o?.value || ''
  }
];

function CreditPage(props) {
  const navigate = useNavigate();
  const translator = useContext(Translator.Context);

  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { links = [], pageName = '' } = props;

  const { data, isLoading: isLoading } = useQuery(['credit', filters, sort, currentPage], () =>
    queries.getAllWithPagination(`/api/credits`, {
      filters: strapiFiltersTranslator(filters, config(translator)),
      sort: sort,
      populate: '*',
      pagination: {
        page: currentPage,
        pageSize: 20
      }
    })
  );

  const tableData = isLoading ? [] : data?.data;
  const pagination = isLoading ? {} : data?.meta?.pagination;

  return (
    <Layout links={links} pageName={pageName}>
      <Section
        title={translator('Crédits')}
        description={translator('Liste des credits')}
        onClick={() => navigate('/edit/credit')}>
        <FilterButton
          className="mt-5"
          config={config(translator)}
          filters={filters}
          setFilters={setFilters}
          label={translator('Filtres')}
          language="fr"
        />
        <Table
          data={tableData}
          config={config(translator)}
          onClick={(_, o) => navigate(`/edit/credit/${o?.id}`)}
          className={'mt-2 sm:mt-5'}
          isLoading={isLoading}
          sort={sort}
          setSort={setSort}
        />
        {!isLoading && (
          <div className="flex justify-between py-6">
            <PaginationText pagination={pagination} />
            <Pagination
              maxPage={pagination?.pageCount}
              range={5}
              currentPage={Math.min(currentPage, pagination?.pageCount) || 1}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </Section>
    </Layout>
  );
}

export default CreditPage;
