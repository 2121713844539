import { useState, useContext } from 'react';
import { Section, Layout } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { PaginationText } from '@custom';
import { Table, FilterButton, Pagination } from '@components';
import { strapiFiltersTranslator } from '@queries/translators';
import moment from 'moment';
import { Translator } from '@context';

const headers = (translator) => [
  {
    key: 'id',
    label: 'Id',
    type: 'integer',
    display: (o) => o?.id,
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'room.name',
    label: `${translator('Salle')}`,
    type: 'relation',
    display: (o) => o?.room?.name || '',
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'user.username',
    label: `${translator('Utilisateur')}`,
    type: 'relation',
    display: (o) => o?.user?.username || '',
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'name',
    label: `${translator('Description')}`,
    type: 'string',
    display: (o) =>
      (
        <>
          <div className="hidden md:table-cell">{o?.name}</div>
          <div className="table-cell md:hidden">
            <div className="text-gray-900">
              {o?.name} - {o?.user?.username}
            </div>
            <div className="mt-1 text-gray-500">
              {o?.start ? moment(o?.start).format('DD/MM/YYYY HH:mm') : ''} -
              {o?.end ? moment(o?.end).format('DD/MM/YYYY HH:mm') : ''}
            </div>
          </div>
        </>
      ) || ''
  },
  {
    key: 'start',
    label: `${translator('Date de début')}`,
    type: 'datetime',
    display: (o) => (o?.start ? moment(o?.start).format('DD/MM/YYYY HH:mm') : ''),
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'end',
    label: `${translator('Date de fin')}`,
    type: 'datetime',
    display: (o) => (o?.end ? moment(o?.end).format('DD/MM/YYYY HH:mm') : ''),
    classNameColumn: 'hidden md:table-cell'
  }
  // {
  //   key: 'group',
  //   label: `${translator('Groupe')}`,
  //   type: 'text',
  //   display: (o) => o?.group?.name,
  //   classNameColumn: 'hidden md:table-cell'
  // }
];

function BookingPage(props) {
  const navigate = useNavigate();
  const translator = useContext(Translator.Context);

  const [filters, setFilters] = useState([
    {
      field: {
        key: 'end',
        label: 'Date de fin',
        type: 'datetime',
        classNameColumn: 'hidden md:table-cell'
      },
      option: { val: '$gte', fr: 'Supérieur ou égal à', en: 'Greater than or equal to' },
      value: moment().format('YYYY-MM-DDTHH:mm')
    }
  ]);
  const [sort, setSort] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { links = [], pageName = '' } = props;

  const { data, isLoading: isLoading } = useQuery(['booking', filters, sort, currentPage], () =>
    queries.getAllWithPagination(`/api/bookings`, {
      populate: '*',
      filters: strapiFiltersTranslator(filters, headers(translator)),
      sort: sort,
      pagination: {
        page: currentPage,
        pageSize: 20
      }
    })
  );

  const tableData = isLoading ? [] : data?.data;
  const pagination = isLoading ? {} : data?.meta?.pagination;

  return (
    <Layout links={links} pageName={pageName}>
      <Section
        title={translator('Réservations')}
        description={translator('Liste des réservations')}
        onClick={() => navigate('/edit/booking')}>
        <FilterButton
          className="mt-5"
          config={headers(translator)}
          filters={filters}
          setFilters={setFilters}
          label={translator('Filtres')}
          language="fr"
        />
        <Table
          data={tableData}
          onClick={(_, o) => navigate(`/edit/booking/${o?.id}`)}
          isLoading={isLoading}
          sort={sort}
          className={'mt-2 sm:mt-5'}
          setSort={setSort}
          config={headers(translator)}
        />
        {!isLoading && (
          <div className="flex justify-between py-6">
            <PaginationText pagination={pagination} />
            <Pagination
              maxPage={pagination?.pageCount}
              range={5}
              currentPage={Math.min(currentPage, pagination?.pageCount) || 1}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </Section>
    </Layout>
  );
}

export default BookingPage;
