import { useState, useContext } from 'react';
import { Section, PaginationText, Layout } from '@custom';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import queries from '@queries';
import { Table, FilterButton, Pagination } from '@components';
import { strapiFiltersTranslator } from '@queries/translators';
import { Translator } from '@context';

const config = (translator) => [
  {
    key: 'id',
    label: `${translator('Id')}`,
    type: 'integer',
    display: (o) => o?.id,
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'name',
    label: `${translator('Group')}`,
    type: 'text',
    display: (o) => (
      <>
        <div className="hidden md:table-cell">{o?.name || ''}</div>
        <div className="table-cell md:hidden">
          <div className="text-gray-900">{o?.name}</div>
        </div>
      </>
    )
  },
  {
    key: 'visibility',
    label: `${translator('Visibilité')}`,
    type: 'text',
    display: (o) => o?.visibility || '',
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'creationCost',
    label: `${translator('Coût de création')}`,
    type: 'integer',
    display: (o) => o?.creationCost || '',
    classNameColumn: 'hidden md:table-cell'
  },
  {
    key: 'participationCost',
    label: `${translator('Coût de participation')}`,
    type: 'integer',
    display: (o) => o?.participationCost || '',
    classNameColumn: 'hidden md:table-cell'
  }
];

function GroupPage(props) {
  const navigate = useNavigate();
  const translator = useContext(Translator.Context);
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const { links = [], pageName = '' } = props;

  const { data, isLoading: isLoading } = useQuery(['group', filters, sort, currentPage], () =>
    queries.getAllWithPagination(`/api/groups`, {
      filters: strapiFiltersTranslator(filters, config(translator)),
      sort: sort,
      pagination: {
        page: currentPage,
        pageSize: 20
      }
    })
  );

  const tableData = isLoading ? [] : data?.data;
  const pagination = isLoading ? {} : data?.meta?.pagination;

  return (
    <Layout links={links} pageName={pageName}>
      <Section
        title="Groupes"
        description="Liste des groupes"
        onClick={() => navigate('/edit/group')}>
        <FilterButton
          className="mt-5"
          config={config(translator)}
          filters={filters}
          setFilters={setFilters}
          label={translator('Filtres')}
          language="fr"
        />
        <Table
          data={tableData}
          config={config(translator)}
          onClick={(_, o) => navigate(`/edit/group/${o?.id}`)}
          className={'mt-2 sm:mt-5'}
          isLoading={isLoading}
          sort={sort}
          setSort={setSort}
        />
        {!isLoading && (
          <div className="flex justify-between py-6">
            <PaginationText pagination={pagination} />
            <Pagination
              maxPage={pagination?.pageCount}
              range={5}
              currentPage={Math.min(currentPage, pagination?.pageCount) || 1}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
      </Section>
    </Layout>
  );
}

export default GroupPage;
