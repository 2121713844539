const Badge = (props) => {
  const { color = 'yellow', children = null } = props;

  return (
    <span
      className={`inline-flex items-center rounded-md bg-${color}-50 dark:bg-${color}-400/10 px-2 py-1 text-xs font-medium text-${color}-800 dark:text-${color}-400 ring-1 ring-inset ring-${color}-600/20 dark:ring-${color}-400/20`}>
      {children}
    </span>
  );
};

export default Badge;
