import React, { useState, useContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm, Layout } from '@custom';
import { InputText } from '@components';
import { Translator } from '@context';

import queries from '@queries';

const RoomEdit = (props) => {
  const { id } = useParams();
  const [openingHour, setOpeningHour] = useState(null);
  const [closingHour, setClosingHour] = useState(null);
  const translator = useContext(Translator.Context);

  const { links = [], pageName = '' } = props;

  const { data: room } = useQuery(
    ['room', id],
    () => queries.get(`/api/rooms`, id, { populate: '*' }).then((res) => res.data),
    {
      retry: false,
      enabled: !(typeof id === 'undefined' || id === null),
      onSuccess: (data) => {
        setOpeningHour(data?.openingHour);
        setClosingHour(data?.closingHour);
      }
    }
  );

  return (
    <Layout links={links} pageName={pageName}>
      <EditForm
        title={translator('Salles')}
        description={translator(
          "Ce formulaire permet de modifier les informations liées aux salles, tel que la capacité, les horaires d'ouverture ou de fermeture."
        )}>
        <input type="hidden" name="id" defaultValue={room?.id} />
        <input
          type="hidden"
          name="openingHour"
          value={moment(openingHour, 'HH:mm:ss').format('HH:mm:ss.SSS')}
        />
        <input
          type="hidden"
          name="closingHour"
          value={moment(closingHour, 'HH:mm:ss').format('HH:mm:ss.SSS')}
        />
        <InputText
          className="col-span-full"
          name="name"
          label={translator('Nom de la salle *')}
          defaultValue={room?.name}
          required
        />
        <InputText
          className="col-span-full"
          name="capacity"
          label={translator('Capacité')}
          type="number"
          defaultValue={room?.capacity}
        />
        <InputText
          label={translator("Heure d'ouverture")}
          className="col-span-full"
          type="time"
          value={openingHour}
          onChange={(e) => setOpeningHour(e.target.value)}
          required
        />
        <InputText
          label={translator('Heure de fermeture')}
          className="col-span-full"
          type="time"
          value={closingHour}
          onChange={(e) => setClosingHour(e.target.value)}
          required
        />
      </EditForm>
    </Layout>
  );
};

export default RoomEdit;
