import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Sidebar, MobileNavBar } from '@layouts';
import { ContextDarkMode } from '@components';
import { Translator, Configuration } from '@context';
import { PowerIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';
import queries from '@queries';
import logo from '@assets/logo.png';

const initialsFromEmail = (email) =>
  email
    ?.split('@')?.[0]
    ?.split('.')
    ?.map((word) => word?.toUpperCase()?.[0])
    ?.join('');

const Layout = (props) => {
  const { links = [], pageName = '', children = null } = props;
  const { darkMode, updateDarkMode } = useContext(ContextDarkMode);

  const translator = useContext(Translator.Context);
  const configuration = useContext(Configuration.Context);

  const me = useQuery(['me'], () => queries.getAll('/api/users/me', { populate: '*' }), {
    retry: false
  });

  const linksToRender = links?.map((value) => ({
    name: value?.name || '',
    current: value?.pageName === pageName,
    href: `/${value?.pageName}`,
    icon: value?.icon,
    isTitle: value?.isTitle || false,
    isMobile: value?.isMobile || false
  }));

  if (!configuration.enableGroups)
    linksToRender.splice(
      linksToRender.findIndex((link) => link.name === 'Groupes'),
      1
    );

  if (!configuration.enableCredits)
    linksToRender.splice(
      linksToRender.findIndex((link) => link.name === 'Crédits'),
      1
    );

  return (
    <main className="flex flex-1 flex-col lg:flex-row dark:bg-slate-900 h-screen justify-between">
      <Sidebar
        logoLink="/"
        title={translator('Thoody Booking')}
        classNameLogo="h-7"
        className="transition"
        tabs={linksToRender}
        userActions={[
          {
            icon: darkMode ? SunIcon : MoonIcon,
            title: darkMode ? translator('Mode Clair') : translator('Mode Sombre'),
            fnc: updateDarkMode
          },
          {
            icon: PowerIcon,
            title: translator('Déconnexion'),
            fnc: () => {
              window.location.replace('/');
              sessionStorage.removeItem('token');
            }
          }
        ]}
        logo={logo}
        avatar={initialsFromEmail(me?.data?.email)}
        username={me?.data?.username}
      />
      {children}
      <MobileNavBar tabs={linksToRender} />
    </main>
  );
};

export default Layout;
