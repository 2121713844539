import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Translator } from '@context';
import { EditForm, Layout } from '@custom';
import { InputText, Combobox } from '@components';

import queries from '@queries';

const CreditEdit = (props) => {
  const { id } = useParams();
  const translator = useContext(Translator.Context);

  const { links = [], pageName = '' } = props;

  const { data: credit } = useQuery(
    ['credit', id],
    () => queries.get(`/api/credits`, id, { populate: '*' }).then((res) => res.data),
    {
      retry: false,
      enabled: !(typeof id === 'undefined' || id === null)
    }
  );

  const { data: users } = useQuery(
    ['user'],
    () => queries.getAll(`/api/users`, { populate: '*' }),
    { retry: false }
  );

  const { data: groups } = useQuery(
    ['group'],
    () => queries.getAll(`/api/groups`, { populate: '*' }).then((res) => res.data),
    { retry: false }
  );

  return (
    <Layout links={links} pageName={pageName}>
      <EditForm
        title={translator('Crédits')}
        description={translator(
          'Ce formulaire permet de modifier les informations liées aux Groupes.'
        )}>
        <input type="hidden" name="id" defaultValue={credit?.id} />
        <Combobox
          className="col-span-full"
          name="owner"
          label={translator('User')}
          data={users}
          displayValue={(r) => r?.username || ''}
          displayOptions={(r) => r?.username || ''}
          filter={(p, query) => p?.username?.toLowerCase()?.includes(query.toLowerCase())}
          defaultValue={credit?.owner}
          noDataLabel={translator('Aucun username ne correspond à cette recherche ...')}
        />
        <Combobox
          className="col-span-full"
          name="multiple.groups"
          label={translator('Groupes')}
          data={groups}
          displayValue={(r) => r?.name || ''}
          displayOptions={(r) => r?.name || ''}
          filter={(p, query) => p?.name?.toLowerCase().includes(query.toLowerCase())}
          defaultValue={credit?.groups}
          noDataLabel={translator('Aucun groupe ne correspond à cette recherche ...')}
          multiple
        />
        <InputText
          className="col-span-full"
          name="value"
          label={translator('Nombre de crédit')}
          type="number"
          defaultValue={credit?.value}
        />
      </EditForm>
    </Layout>
  );
};

export default CreditEdit;
