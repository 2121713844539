import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Translator } from '@context';
import { EditForm, Layout } from '@custom';
import { InputText, Combobox } from '@components';

import queries from '@queries';

const GroupEdit = (props) => {
  const { id } = useParams();
  const translator = useContext(Translator.Context);

  const { links = [], pageName = '' } = props;

  const { data: group } = useQuery(
    ['group', id],
    () => queries.get(`/api/groups`, id, { populate: '*' }).then((res) => res.data),
    {
      retry: false,
      enabled: !(typeof id === 'undefined' || id === null)
    }
  );

  const { data: users } = useQuery(
    ['user'],
    () => queries.getAll(`/api/users`, { populate: '*' }),
    { retry: false }
  );

  return (
    <Layout links={links} pageName={pageName}>
      <EditForm
        title={translator('Groupes')}
        description={translator(
          'Ce formulaire permet de modifier les informations liées aux Groupes.'
        )}>
        <input type="hidden" name="id" defaultValue={group?.id} />
        <InputText
          className="col-span-full"
          name="name"
          label={translator('Nom du Groupe *')}
          defaultValue={group?.name}
          required
        />
        <Combobox
          className="col-span-full"
          name="visibility"
          label={translator('Visibilité')}
          data={['public', 'private', 'groupLevel']}
          displayValue={(r) => r || ''}
          displayOptions={(r) => r || ''}
          filter={(p, query) => p?.toLowerCase()?.includes(query.toLowerCase())}
          defaultValue={group?.visibility}
          valueKey={(data) => data}
          noDataLabel={translator('Aucun type de visibilité ne correspond à cette recherche ...')}
        />
        <InputText
          className="col-span-full"
          name="creationCost"
          label={translator('Coût de la création')}
          type="number"
          defaultValue={group?.creationCost}
        />
        <InputText
          className="col-span-full"
          name="participationCost"
          label={translator('Coût de la participation')}
          type="number"
          defaultValue={group?.participationCost}
        />
        <Combobox
          className="col-span-full"
          name="multiple.creators"
          label={translator('Creators')}
          data={users}
          displayValue={(r) => r?.username || ''}
          displayOptions={(r) => r?.username || ''}
          filter={(p, query) => p?.username?.toLowerCase()?.includes(query.toLowerCase())}
          defaultValue={group?.creators}
          noDataLabel={translator('Aucun username ne correspond à cette recherche ...')}
          multiple
        />
        <Combobox
          className="col-span-full"
          name="multiple.participants"
          label={translator('Participants')}
          data={users}
          displayValue={(r) => r?.username || ''}
          displayOptions={(r) => r?.username || ''}
          filter={(p, query) => p?.username?.toLowerCase().includes(query.toLowerCase())}
          defaultValue={group?.participants}
          noDataLabel={translator('Aucun username ne correspond à cette recherche ...')}
          multiple
        />
      </EditForm>
    </Layout>
  );
};

export default GroupEdit;
