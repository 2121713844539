export const optionsConfig = [
  { val: '$eq', fr: 'Égal', en: 'Equal' },
  {
    val: '$eqi',
    fr: 'Égal (non sensible à la case)',
    en: 'Equal (case-insensitive)'
  },
  { val: '$ne', fr: 'Non égal', en: 'Not equal' },
  { val: '$lt', fr: 'Inférieur à', en: 'Less than' },
  { val: '$lte', fr: 'Inférieur ou égal à', en: 'Less than or equal to' },
  { val: '$gt', fr: 'Supérieur à', en: 'Greater than' },
  { val: '$gte', fr: 'Supérieur ou égal à', en: 'Greater than or equal to' },
  {
    val: '$in',
    fr: 'Inclus dans les valeurs suivantes',
    en: 'Included in an array'
  },
  {
    val: '$notIn',
    fr: 'Non inclus dans les valeurs suivantes',
    en: 'Not included in an array'
  },
  { val: '$contains', fr: 'Contient', en: 'Contains' },
  { val: '$notContains', fr: 'Ne contient pas', en: 'Does not contain' },
  {
    val: '$containsi',
    fr: 'Contient (non sensible à la case)',
    en: 'Contains (case-insensitive)'
  },
  {
    val: '$notContainsi',
    fr: 'Ne contient pas (non sensible à la case)',
    en: 'Does not contain (case-insensitive)'
  },
  { val: '$null', fr: 'Est vide', en: 'Is null' },
  { val: '$notNull', fr: "N'est pas vide", en: 'Is not null' },
  { val: '$between', fr: 'Est entre les valeurs', en: 'Is between' },
  { val: '$startsWith', fr: 'Commence par ', en: 'Starts with' },
  {
    val: '$startsWithi',
    fr: 'Commence par (non sensible à la case)',
    en: 'Starts with (case-insensitive)'
  },
  { val: '$endsWith', fr: 'Fini par ', en: 'Ends with' },
  {
    val: '$endsWithi',
    fr: 'Fini par (non sensible à la case)',
    en: 'Ends with (case-insensitive)'
  }
];

// field type depending types
export const fieldsBasedOnType = (type) =>
  ({
    // String types
    string: 'text',
    text: 'text',
    richtext: 'text',
    enumeration: 'text',
    email: 'email',
    password: 'password',
    uid: 'text',
    // Number types
    number: 'number',
    integer: 'number',
    biginteger: 'number',
    float: 'number',
    decimal: 'number',
    // Boolean
    boolean: 'number',
    // Dates
    date: 'date',
    time: 'time',
    datetime: 'datetime-local',
    timestamp: 'date',
    // Relation
    relation: 'text'
  }[type]);

// It is going to exclude cases of options based on the type of the fields to only display relevant ones
export const choicesBasedOnType = (type, choices) => {
  const stringChoices = choices.filter(
    (c) => !['$lt', '$lte', '$gt', '$gte', '$in', '$notIn', '$between'].includes(c?.val)
  );

  const numberChoices = choices.filter(
    (c) =>
      ![
        '$in',
        '$notIn',
        '$contains',
        '$containsi',
        '$notContainsi',
        '$startsWith',
        '$startsWithi',
        '$endsWith',
        '$endsWithi'
      ].includes(c?.val)
  );

  const dateChoices = choices.filter(
    (c) =>
      ![
        '$in',
        '$notIn',
        '$contains',
        '$containsi',
        '$notContainsi',
        '$startsWith',
        '$startsWithi',
        '$endsWith',
        '$endsWithi'
      ].includes(c?.val)
  );

  return {
    // String types
    string: stringChoices,
    text: stringChoices,
    richtext: stringChoices,
    enumeration: stringChoices,
    email: stringChoices,
    password: stringChoices,
    uid: stringChoices,
    // Number types
    number: numberChoices,
    integer: numberChoices,
    biginteger: numberChoices,
    float: numberChoices,
    decimal: numberChoices,
    // Boolean
    boolean: choices[0],
    // Dates
    date: dateChoices,
    time: dateChoices,
    datetime: dateChoices,
    timestamp: dateChoices,
    // Relations
    relation: choices.filter(
      (c) => !['$lt', '$lte', '$gt', '$gte', '$in', '$notIn', '$between'].includes(c?.val)
    )
  }[type];
};
