import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { EditForm } from '@custom';
import { InputText, Combobox } from '@components';
import { Translator } from '@context';
import queries from '@queries';

const UserEdit = () => {
  const { id } = useParams();
  const translator = useContext(Translator.Context);

  // const { links = [], pageName = '' } = props;

  const { data: user } = useQuery(
    ['user', id],
    () => queries.get(`/api/users`, id, { populate: '*' }),
    { enabled: !!id }
  );

  const { data: roles } = useQuery(['roles'], () =>
    queries.getAll(`/api/users-permissions/roles`).then((res) => res.roles)
  );

  // const { data: groups } = useQuery(
  //   ['group'],
  //   () => queries.getAll(`/api/groups`, { populate: '*' }).then((res) => res.data),
  //   { retry: false }
  // );

  return (
    <EditForm
      title={translator('Utilisateurs')}
      description={translator(
        'Ce formulaire permet de modifier les informations liées aux utilisateurs, tel que leur username.'
      )}
      raw={true}>
      <input type="hidden" name="password" value={'Test-1234'} disabled={!!id} />
      <input type="hidden" name="id" value={user?.id} />
      <InputText
        className="col-span-full"
        name="username"
        label={translator('Username *')}
        defaultValue={user?.username}
        required
      />
      <InputText
        className="col-span-full"
        name="email"
        label={translator('Email *')}
        type="email"
        defaultValue={user?.email}
        required
      />
      <Combobox
        className="col-span-full"
        name="role"
        label={translator('Status *')}
        data={roles}
        displayValue={(r) => r?.name || ''}
        displayOptions={(r) => r?.name || ''}
        filter={(p, query) => p?.name?.toLowerCase().includes(query.toLowerCase())}
        defaultValue={user?.role}
        noDataLabel={translator('Aucun role ne correspond à cette recherche ...')}
        required
      />
      {/* <Combobox
        className="col-span-full"
        name="multiple.creationGroups"
        label={translator('Groupes de création')}
        data={groups}
        displayValue={(r) => r?.name || ''}
        displayOptions={(r) => r?.name || ''}
        filter={(p, query) => p?.name?.toLowerCase().includes(query.toLowerCase())}
        defaultValue={user?.creationGroups}
        noDataLabel={translator('Aucun groupe ne correspond à cette recherche ...')}
        multiple
      />
      <Combobox
        className="col-span-full"
        name="multiple.participationGroups"
        label={translator('Groupes de participation')}
        data={groups}
        displayValue={(r) => r?.name || ''}
        displayOptions={(r) => r?.name || ''}
        filter={(p, query) => p?.name?.toLowerCase().includes(query.toLowerCase())}
        defaultValue={user?.participationGroups}
        noDataLabel={translator('Aucun groupe ne correspond à cette recherche ...')}
        multiple
      /> */}
    </EditForm>
  );
};

export default UserEdit;
