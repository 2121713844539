import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { FunnelIcon } from '@heroicons/react/24/outline';
import { Combobox, InputText, Button } from '@components';

import { optionsConfig, choicesBasedOnType, fieldsBasedOnType } from './config';
import Badge from './Badge';

const FilterButton = (props) => {
  const {
    className = '',
    classNameButton = '',
    label = 'Filters',
    language = 'en',
    config = [],
    filters = [],
    setFilters = () => {},
    customOptions = null
  } = props;

  // Define states to handle the different fields in the popup
  const [field, setField] = useState(null);
  const [option, setOption] = useState(null);
  const [value, setValue] = useState(null);

  // Make value an array for the $between command
  const setArrayValue = (index, val) =>
    setValue((prev) =>
      Array.isArray(prev)
        ? prev.toSpliced(index, 1, val)
        : Array(2).fill(null).toSpliced(index, 1, val)
    );

  const removeFilter = (index) => setFilters((prev) => prev.toSpliced(index, 1));

  const isValid =
    field && option && (Array.isArray(value) ? value.every((v) => v !== null) : value);
  const buttonRef = useRef();
  const trueValues = ['$null', '$notNull'];

  // validate the selection in the modal
  const validate = (close) => {
    if (isValid) {
      setFilters((prev) => [...prev, { field, option, value }]);
      close(buttonRef);
    }
  };

  // Reset value on change
  useEffect(() => {
    setOption(null);
    setValue('');
  }, [field]);

  // Set value to true automatically for $null and $notNull
  useEffect(() => {
    if (trueValues.includes(option?.val)) setValue(true);
    else setValue('');
  }, [option]);

  return (
    <div className={'flex ' + className}>
      <Popover className="relative">
        <Popover.Button
          className={
            'flex justify-between rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 outline-none dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:ring-0' +
            classNameButton
          }>
          <FunnelIcon className="h-5 mr-2" />
          {label}
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95">
          <Popover.Panel
            className={`fixed max-h-[300px] min-w-[300px] z-40 mt-2 origin-top-right rounded-md bg-white dark:bg-gray-900 dark:border dark:border-gray-600 p-4 shadow-2xl ring-1 ring-zinc-900 ring-opacity-5 focus:outline-none transition flex flex-col`}
            ref={buttonRef}>
            {({ close }) => (
              <>
                <Combobox
                  icon={null}
                  data={config}
                  valueKey={(v) => v?.key}
                  displayValue={(v) => v?.label || v?.key}
                  displayOptions={(v) => v?.label || v?.key}
                  filter={(v, q) => (v?.label || v?.key)?.toLowerCase()?.includes(q?.toLowerCase())}
                  value={field}
                  setValue={setField}
                  autoFocus
                  isControlled
                />
                <Combobox
                  icon={null}
                  data={choicesBasedOnType(field?.type, customOptions ?? optionsConfig)}
                  valueKey={(v) => v?.val}
                  displayValue={(v) => v?.[language]}
                  displayOptions={(v) => v?.[language]}
                  filter={(v, q) => v?.[language]?.toLowerCase()?.includes(q?.toLowerCase())}
                  value={option}
                  setValue={setOption}
                  isControlled></Combobox>
                {!trueValues.includes(option?.val) && option?.val !== '$between' && (
                  <InputText
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    onKeyDown={(e) => e.keyCode == 13 && validate(close)}
                    type={fieldsBasedOnType(field?.type)}
                  />
                )}
                {option?.val === '$between' && (
                  <>
                    <InputText
                      value={value?.[0]}
                      onChange={(e) => setArrayValue(0, e.target.value)}
                      onKeyDown={(e) => e.keyCode == 13 && validate(close)}
                      type={fieldsBasedOnType(field?.type)}
                    />
                    <InputText
                      value={value?.[1]}
                      onChange={(e) => setArrayValue(1, e.target.value)}
                      onKeyDown={(e) => e.keyCode == 13 && validate(close)}
                      type={fieldsBasedOnType(field?.type)}
                    />
                  </>
                )}
                <Button
                  className="mt-2 self-end"
                  onClick={() => validate(close)}
                  disabled={!isValid}>
                  {
                    {
                      en: 'Add',
                      fr: 'Ajouter'
                    }[language]
                  }
                </Button>
              </>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
      <div className="flex items-center ml-2">
        {filters?.map((v, index) => (
          <Badge
            key={`option_filter_${index}`}
            label={`${v?.field?.label || v?.field?.field} ${v?.option?.[language]} ${
              trueValues?.includes(v?.option?.val) ? '' : v?.value
            }`}
            onClick={() => removeFilter(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterButton;
