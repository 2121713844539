import { useContext, Fragment } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { PowerIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';

import logo from '@assets/logo.png';
import { ContextDarkMode } from '@components';
import { Header } from '@layouts';
import queries from '@queries';
import { Translator, Configuration } from '@context';

const initialsFromEmail = (email) =>
  email
    ?.split('@')?.[0]
    ?.split('.')
    ?.map((word) => word?.toUpperCase()?.[0])
    ?.join('');

function SideBar(props) {
  const { darkMode, updateDarkMode } = useContext(ContextDarkMode);
  // const { pathname } = useLocation();
  const translator = useContext(Translator.Context);
  const configuration = useContext(Configuration.Context);
  const params = useParams();
  const { links = [], paramKey = 'pageName' } = props;

  const me = useQuery(['me'], () => queries.getAll('/api/users/me', { populate: '*' }), {
    retry: false
  });

  const SelectedComponent =
    links?.find((o) => o?.pageName == params?.[paramKey])?.render || Fragment;

  const linksToRender = links?.map((value) => ({
    name: value?.name || '',
    current: value?.pageName === params?.[paramKey],
    href: `/${value?.pageName}`,
    icon: value?.icon,
    isTitle: value?.isTitle || false
  }));

  if (!configuration.enableGroups)
    linksToRender.splice(
      linksToRender.findIndex((link) => link.name === 'Groupes'),
      1
    );

  if (!configuration.enableCredits)
    linksToRender.splice(
      linksToRender.findIndex((link) => link.name === 'Crédits'),
      1
    );

  return (
    <Header
      logoLink="/"
      title={translator('Thoody Booking')}
      classNameLogo="h-7"
      className="transition overflow-hidden"
      style="SideBar"
      tabs={linksToRender}
      userActions={[
        {
          icon: darkMode ? SunIcon : MoonIcon,
          title: darkMode ? translator('Mode Clair') : translator('Mode Sombre'),
          fnc: updateDarkMode
        },
        {
          icon: PowerIcon,
          title: translator('Déconnexion'),
          fnc: () => {
            window.location.replace('/');
            sessionStorage.removeItem('token');
          }
        }
      ]}
      logo={logo}
      avatar={initialsFromEmail(me?.data?.email)}
      username={me?.data?.username}>
      <div className="flex flex-col px-0 lg:px-10 pt-10 h-full min-h-screen dark:bg-slate-900">
        <SelectedComponent />
      </div>
    </Header>
  );
}

export default SideBar;
