import * as queries from '@queries/utils';
import qs from 'qs';

const generateQuery = (payload) =>
  qs.stringify(payload, {
    encodeValuesOnly: true // prettify URL
  });

export { displayError } from './utils';

export default {
  getAll: (url, query = {}, ...params) =>
    queries.getQuery(`${url}`, generateQuery(query), ...params),
  get: (url, id, query = {}, ...params) =>
    queries.getQuery(`${url}/${id}`, generateQuery(query), ...params),
  post: (url, ...params) => queries.postQuery(`${url}`, ...params),
  put: (url, ...params) => queries.putQuery(`${url}`, ...params),
  delete: (url, ...params) => queries.deleteQuery(`${url}`, ...params),
  // Get with pagination
  getAllWithPagination: (url, query, ...params) =>
    queries.getQueryWithPagination(`${url}`, generateQuery(query), ...params)
};
