import { useState, useEffect } from 'react';

const RadioGroup = (props) => {
  const {
    className = '',
    classNameLabel = '',
    label = '',
    data = [],
    name = '',
    value: valueControlled = null,
    setValue: setValueControlled = () => {},
    defaultValue = null,
    isControlled = false,
    isHorizontal = false,
    ...rest
  } = props;

  const [valueInternal, setValueInternal] = useState(defaultValue);

  const value = isControlled ? valueControlled : valueInternal;
  const setValue = isControlled ? setValueControlled : setValueInternal;

  useEffect(() => {
    setValueInternal(defaultValue);
  }, [defaultValue]);

  return (
    <div className={className}>
      <label
        className={`block text-sm font-medium leading-6 text-gray-900 dark:text-white ${classNameLabel}`}>
        {label}
      </label>
      <fieldset className={label.length > 0 && 'mt-4'} onChange={(e) => setValue(e.target.value)}>
        <legend className="sr-only">{label}</legend>
        <div
          className={`space-y-4 ${
            isHorizontal && 'sm:flex sm:items-center sm:space-x-10 sm:space-y-0'
          }`}>
          {data?.map((v, index) => (
            <div key={`radio_button_${index}`} className="flex items-center">
              <input
                id={`radio_button_${name}_${index}`}
                name={name}
                value={v?.value}
                type="radio"
                className="h-4 w-4 cursor-pointer border-gray-300 text-indigo-600 focus:ring-0 focus:ring-offset-0 dark:border-white/10 dark:bg-white/5 dark:text-indigo-600 checked:dark:bg-indigo-600"
                checked={v?.value === value}
                onChange={() => {}} // To prevent warning
                {...rest}
              />
              <label
                htmlFor={`radio_button_${name}_${index}`}
                className="font-sm ml-3 block cursor-pointer text-sm leading-6 text-gray-600 dark:text-gray-400">
                {v?.label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  );
};

export default RadioGroup;
